:root {
  /* Main theme */
  --background-theme-colour: rgb(0, 74, 149);

  /* Button theme*/
  --background-button-colour: rgb(249, 174, 0);
  --button-hover-colour: #ec971f;
  --button-text-colour: white;
  --button-hover-transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
  --button-hover-box-shadow: 0 5px 3px 2px #0003,0 4px 4px 0 #00000024,0 3px 7px 0 #0000001f;

  /* Menu theme */
  --menu-text-colour: #a9a9a9;
  --menu-hover-text-colour: gray;
  --menu-hover-background-colour: #e5e7e6;
  --menu-active-background-colour: #e5e7e6;
  --menu-active-text-colour: gray;
  --menu-background-colour: rgb(255, 255, 255);

  /** Log in */
  --login-background: linear-gradient(to bottom, #03174b, #03a6f0);
  --login-form-background: rgba(3, 23, 75, 0.5);
  --login-logo-width: 50%;

  /* Spinner icon */
  --loading-gif-colour: rgb(249, 174, 0);
  --login-loading-gif-colour: rgb(249, 174, 0);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
